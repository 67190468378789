
export default function WrapPromise<T>(promise: Promise<T>) {
  let status = 'pending';
  let error: unknown;
  let response: T;

  const suspender = promise.then(
    (res) => {
      status = 'success';
      response = res;
    },
    (err) => {
      status = 'error';
      error = err;
    },
  );

  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender;
      case 'error':
        throw error;
      default:
        return response;
    }
  };

  return { read };
}
