import { Suspense, type PropsWithChildren } from "react";
import PageContext from "./../PageContext";
import { NavBar, Spinner } from "@virtalis/reach-theme-components";
import Toaster from "./../../src/components/Toaster";
import Auth from "./../Auth";
import UserProfile from "./UserProfile";
// TODO: automatic injection of theme styling
import '@virtalis/reach-theme-components/dist/index.css';
import css from './PageShell.module.scss';
import WrapPromise from "./WrapPromise";

const awaitingAuth = WrapPromise(Auth);

export default function PageShell({ children }: PropsWithChildren) {
  return <>
    <Suspense fallback={<Spinner />}>
      <PageContext.Provider value={awaitingAuth.read()} >
        <NavBar>
          <NavBar.Menu>
            <div style={{
              padding: 3,
              margin: 8
            }}>
              <div style={{
                marginRight: -8,
                display: 'flex',
                gap: -8,
              }}>
                <PageContext.Consumer>
                  {
                    value =>
                      value.profile?.roles?.includes('vrdb-upload') ?
                        <a className={css['navLink']} href='/hub/library'>Import an asset</a>
                        : value.profile?.roles?.includes('project-author') &&
                        <a className={css['navLink']} href='/hub'>Create a project</a>
                  }
                </PageContext.Consumer>

                {
                  location.pathname !== '/legacy' && (<a className={css['navLink']} href='/sessions'>Live sessions</a>)
                }
              </div>
            </div>
            <UserProfile />
          </NavBar.Menu>
        </NavBar>
        <div style={{ position: 'relative' }} >
          <Toaster>
            {children}
          </Toaster>
        </div>
      </PageContext.Provider>
    </Suspense>
  </>;
}
