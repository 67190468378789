import { type MouseEventHandler, useCallback, useRef, type PropsWithChildren, type ReactElement, useContext } from 'react'
import { InlineGroup } from '@virtalis/reach-theme-components'
import { MdContentCopy } from 'react-icons/md';
import ToastContext from './ToastContext';

interface CopyLinkProps {
  uri: string;
  caption?: string;
  className?: string;
  innerClassName?: string;
  linkCopiedMessage: string;
  linkCopyFailMessage: string;
  icon?: string | ReactElement | boolean;
  ariaLabel?: string;
}

export default function CopyLink(props: PropsWithChildren<CopyLinkProps>) {
  const { icon, caption, uri, className, innerClassName, ariaLabel, children, linkCopiedMessage, linkCopyFailMessage } = props;
  const m_linkRef = useRef<HTMLAnchorElement>(null);
  const { showToast } = useContext(ToastContext);

  const copyToClipboard = useCallback<MouseEventHandler<HTMLAnchorElement>>((e) => {
    e.preventDefault();

    const currentTarget = m_linkRef.current;

    if (!currentTarget)
      throw new Error('Failed to find CopyLink anchor element');
    const url = currentTarget.href;

    navigator.clipboard.writeText(url)
      .then(() => {
        if (showToast)
          showToast(linkCopiedMessage, {
            type: 'success'
          })
      }).catch(() => {
        if (showToast)
          showToast(linkCopyFailMessage, {
            type: 'danger'
          })
        else
          console.warn(linkCopyFailMessage);
      });
  }, [linkCopiedMessage, linkCopyFailMessage, showToast]);


  const iconElem =
    typeof icon === 'boolean' ? icon && <MdContentCopy /> :
      typeof icon === 'string' ? <img src={icon} alt="" className="p-icon" /> :
        icon;

  return (
    <InlineGroup as="a" href={uri} className={className} aria-label={ariaLabel} onClick={copyToClipboard} fref={m_linkRef}>
      {iconElem}
      {caption && (
        <div className={innerClassName}>
          {caption}
          {children}
        </div>
      )}
    </InlineGroup>
  );
}
