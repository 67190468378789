import './index.scss'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import PageShell from './components/PageShell.tsx'
import ArtifactPage from './index/ArtifactPage.tsx';
import LoadingPage from './loading/LoadingPage.tsx';
import ErrorPage from './ErrorPage.tsx';
import SessionsPage from './sessions/SessionsPage.tsx';

const container = document.getElementById('root');
if (!container)
  throw new Error("Failed to find react root element");

const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <PageShell>
      {(() => {
        switch (location.pathname) {
          case '/': case '': return <ArtifactPage />;
          case '/legacy': return <ArtifactPage legacyMode />;
          case '/loading': return <LoadingPage />;
          case '/sessions': return <SessionsPage />;
          default: return <ErrorPage is404 />;
        }
      })()}
    </PageShell>
  </StrictMode>,
)
