import artifactThumbnail from '/assets/icon-artifact-loader.png'
import { Alert, Card, CardList } from '@virtalis/reach-theme-components'
import { DateTime } from 'luxon'
import CopyLink from './../../src/components/CopyLink';
import type ArtifactItem from '../types/ArtifactItem';

function toDisplayDate(isoDate = ''): string {
  const dateTime = DateTime.fromISO(isoDate).toLocal().setLocale('en-GB')
  return dateTime.toLocaleString(DateTime.DATETIME_MED);
}

const legacyUri = import.meta.env.VIRTALIS_SESSION_SERVER_LEGACY_URI as string;

export default function SceneSelector(props: {
  fetching: boolean,
  error: string,
  visualisations: ArtifactItem[],
  mode?: 'sessions' | 'legacy' | false
}) {
  const { fetching, error, visualisations, mode } = props;

  // previously we filtered to only show latest revisions,
  // this is no longer required as our endpoint will now filter
  // them for us
  const artifactsToShow = visualisations;

  return (
    <>
      {error && <Alert color="danger">{`${error}. Please refresh the page to try again.`}</Alert>}
      {visualisations && Object.keys(visualisations).length > 0 && (
        <CardList grid>
          {artifactsToShow.map((a) => {
            const uri = mode === 'legacy' ? `${legacyUri}/${a.id}` : `loading?${new URLSearchParams(
              mode === 'sessions' ? {
                sessionId: a.id
              } : {
                artifactId: a.id,
                artifactName: a.name
              }).toString()}`

            return <Card key={a.id} hoverable>
              <Card.Body >
                <a href={uri}>
                  {mode !== 'sessions' && <Card.Image title={a.name} src={artifactThumbnail} />}
                  <Card.Title>{a.name}</Card.Title>
                  <Card.Meta>
                    {`Latest version: ${toDisplayDate(a.createdDate)}`}
                  </Card.Meta>
                  <Card.Meta>
                    {a.labels.map((l) => <div key={a.id + l}>{l}</div>)}
                  </Card.Meta>
                </a>
              </Card.Body>
              {
                mode !== 'sessions' &&
                <div style={{
                  position: 'absolute',
                  right: '1rem',
                  top: '1rem',
                  backgroundColor: 'whitesmoke',
                  boxShadow: 'inset black 0 0px 5px -3px',
                  borderRadius: '0.25rem',
                  aspectRatio: 1,
                  height: 32,
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} >
                  <CopyLink
                    icon={true}
                    ariaLabel="Copy link"
                    uri={uri}
                    linkCopiedMessage="Link to Artifact has been saved to your clipboard"
                    linkCopyFailMessage='Unable to copy link. Please try right clicking on the Artifact and selecting "Copy link address" instead.' />
                </div>
              }
            </Card>
          })}
        </CardList>
      )}
      {(!visualisations || !Object.keys(visualisations).length) && !fetching && !error && (
        <Alert color="info">
          There are no {mode !== 'sessions' ? "scenes" : "sessions"} to display
        </Alert>
      )}
    </>
  );
}
