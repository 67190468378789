import { createContext } from "react";
import type Profile from "./types/Profile";
import type UserAuth from "./types/UserAuth";
import TranslationsSource from './Translations.json'

const firstTranslation = Object.values(TranslationsSource)[0];
type Locale = keyof typeof firstTranslation;

interface PageContext {
  user?: UserAuth;
  profile?: Profile;
  profileUrl?: () => string;
  logout?: VoidFunction;
  locale?: Locale;
}

const Context = createContext<PageContext>({});

export default Context;