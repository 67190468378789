
export default function About() {
  const commitDate = new Date(import.meta.env.VIRTALIS_GIT_COMMIT_DATE as string);

  return <>
    <span>Version: </span>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '1em' }}>
      <span>{import.meta.env.VIRTALIS_GIT_BRANCH_NAME}@{import.meta.env.VIRTALIS_GIT_COMMIT_HASH}</span>
      <small>{commitDate.toLocaleString([], { dateStyle:'short', timeStyle:'short' })}</small>
    </div>
    <br />
    <a href='/client/thirdparty.json' target='_blank' rel='noreferrer noopener'>Third party license information</a>
    {/* TODO: should also put the changelogs here */}
  </>;
}