import { type MouseEventHandler, useCallback, useContext, useRef } from "react";
import PageContext from "./../PageContext";
import { ProfileButton } from "@virtalis/reach-theme-components";
import About from "./About";
import Dialog, { type DialogHandle } from "../../src/components/Dialog";

export default function UserProfile() {
  const { profile, profileUrl, logout } = useContext(PageContext);
  const profileDialogRef = useRef<DialogHandle>(null);
  const aboutDialogRef = useRef<DialogHandle>(null);

  const name = profile?.name ?? "";
  const colour = 'red';// genColourSRGB(profile?.sub) ?? [0.5, 0.5, 0.5];
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const matches = [...name.matchAll(rgx)] || [];
  const initials = (
    (matches.shift()?.[1] ?? '') + (matches.pop()?.[1] ?? '')
  ).toUpperCase();

  const navigateToProfilePage = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (!profileUrl)
      throw new Error("Attempted to navigate to profile page, however url is not defined");
    location.href = profileUrl();
  }, [profileUrl]);

  return profile && <>
    <ProfileButton
      initials={initials ?? '??'}
      borderColor={colour}
      onClick={() => {
        if (profileDialogRef.current)
          profileDialogRef.current.show();
      }}
    />

    <Dialog
      ref={aboutDialogRef}
      modal
      title='About Virtalis Reach'
    >
      <About />
    </Dialog>

    <Dialog
      ref={profileDialogRef}
      automaticallyCloseOnFocusLoss
      title={profile.name}
    >
      <button onClick={navigateToProfilePage}>Profile</button>
      <button onClick={() => {
        if (aboutDialogRef.current)
          aboutDialogRef.current.show();
      }}>About Reach</button>
      <button onClick={logout}>Log out</button>
    </Dialog>
  </>;
}
