import { useCallback, useContext, useState, type ChangeEventHandler, useEffect } from 'react';
import { PageHeader, Pagination, TextInput } from '@virtalis/reach-theme-components';
import SceneSelector from '../components/SceneSelector.tsx';
import Translate from './../components/Translate.tsx';
import PageContext from './../PageContext.ts';
import type ArtifactItem from '../types/ArtifactItem.ts';
import PaginationWrapper from '../../src/components/PaginationWrapper.tsx';
import type CollaborationSession from '../types/CollaborationSession.ts';

const sessionApi = import.meta.env.VIRTALIS_COLLABORATION_SESSION_PATH as string;

export default function SessionsPage() {
  const [nameFilter, setNameFilter] = useState('');
  const pageSize = 100;
  const [pageNo, setPageNo] = useState(1);
  const { user } = useContext(PageContext);
  const [errorMsg, setError] = useState('');
  const [artifacts, setArtifacts] = useState<ArtifactItem[]>([]);
  const [pendingRequest, setPendingRequest] = useState(false);
  const [artifactCount, setArtifactCount] = useState(0);

  const onSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>((v) => {
    const searchBox = v.target;

    setNameFilter(searchBox.value);
    setPageNo(1);
  }, [setPageNo, setNameFilter]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPendingRequest(v => signal.aborted ? v : true);

    fetch(`${sessionApi}?PageNumber=${pageNo}&PageSize=${pageSize}&ArtifactName=${nameFilter}&IsActive=true&Status=3&IsPublic=true&IsLegacy=false`, {
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${user?.access_token}`
      },
      signal,
    }).then((response) => {
      if (!response.ok)
        setError(err => signal.aborted ? err : `Error ${response.status}: ${response.statusText}!`);
      return response.json();
    }).then(data => {
      const { collaborationSessions, totalResults } = data as {
        collaborationSessions?: CollaborationSession[];
        totalResults: number;
      };

      setPendingRequest(v => signal.aborted ? v : false);
      setArtifactCount(v => signal.aborted ? v : totalResults);
      setArtifacts(v => signal.aborted ? v : (collaborationSessions ?? []).filter(session => !session.isLegacy).map(session => {
        const result: ArtifactItem = {
          name: session.artifactName,
          createdDate: session.createdDate,
          id: session.sessionId,
          labels: [`Active partitipants: ${session.actors?.filter(actor => actor.type === 0).map(actor => actor.participants?.length ?? 0).reduce((a, b) => a + b, 0) ?? 0}`],
          visualisationId: session.artifactId
        };
        return result;
      }));
    }).catch((err) => {
      const errorMsg = err instanceof Error ? `Error: ${err.message}` : `Error: ${err}`;
      setError(signal.aborted ? '' : errorMsg);
      setPendingRequest(v => signal.aborted ? v : false);
      setArtifactCount(v => signal.aborted ? v : 0);
      setArtifacts(v => signal.aborted ? v : []);
    });

    return () => {
      controller.abort();
    };
  }, [pageNo, nameFilter, user]);

  return (
    <>
      <PageHeader
        title={("Sessions")}
        subTitle={("An overview of all active sessions")}
      >
        <TextInput
          placeholder={Translate('Filter by name')}
          onChange={onSearchChange}
        />
      </PageHeader>

      <div style={{ margin: '0.75rem 0.5rem' }} >
        <SceneSelector
          error={errorMsg}
          fetching={pendingRequest}
          visualisations={artifacts}
          mode={'sessions'}
        />
      </div>

      <PaginationWrapper>
        <Pagination
          current={pageNo}
          onPageChange={setPageNo}
          total={Math.ceil(artifactCount / pageSize)}
        />
      </PaginationWrapper>
    </>
  )
}
