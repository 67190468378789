import type Profile from './types/Profile.ts'
import type UserAuth from './types/UserAuth.ts'

const settings = {
  authority: `${import.meta.env.VIRTALIS_KEYCLOAK}/auth/realms/reach`,
  client_id: "reach-client",
  redirect_uri: () => `${window.location.origin}/${window.location.pathname}${window.location.search}`,
};

function hasAuthParams(location = window.location) {
  const params = new URLSearchParams(location.search);

  // response_mode: query
  if (params.get("session_state")) {

    const code = params.get("code");
    const error = params.get("error");
    if (!!error || !code)
      console.warn("Authentication error", error ?? 'no auth code provided');

    //cleanup keycloak search params
    params.delete('error');
    params.delete('session_state');
    params.delete('code');

    // update history
    const newurl = window.location.origin + window.location.pathname + (params.size ? '?' + params.toString() : '');
    window.history.pushState({ path: newurl }, '', newurl);

    if (code)
      return code;
  }

  return false;
}

async function GetUserProfile(token: string) {
  const response = await fetch(`${settings.authority}/protocol/openid-connect/userinfo`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return (await response.json()) as Profile;
}

async function RefreshToken(token: string) {
  const response = await fetch(`${settings.authority}/protocol/openid-connect/token`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
          client_id: settings.client_id,
          grant_type: "refresh_token",
          refresh_token: token
      }).toString()
  });

  return await response.json() as {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
  };
}

const code = hasAuthParams();
const Auth = (() => {
  if (code) {
    return fetch(`${settings.authority}/protocol/openid-connect/token`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: settings.client_id,
        grant_type: "authorization_code",
        redirect_uri: settings.redirect_uri(),
        code
      }).toString()
    }).then(r => {
      return r.json();
    }).then(async (user: UserAuth) => {
      if (!user.access_token)
        throw new Error(`Access token not defined ${JSON.stringify(user)}`);
      const profile = await GetUserProfile(user.access_token);

      if (user.refresh_token) {
        const autoRefresh = async () => {
          const updatedTokens = await RefreshToken(user.refresh_token);
          user.access_token = updatedTokens.access_token;
          user.refresh_token = updatedTokens.refresh_token;
          user.expires_in = updatedTokens.expires_in;
          setTimeout(autoRefresh, Math.max(0, user.expires_in * 1000 - 100));
        }
        setTimeout(autoRefresh, Math.max(0, user.expires_in * 1000 - 100));
      }

      return {
        user,
        profile,
        profileUrl: () => {
          return `${settings.authority}/account?referrer=${settings.client_id}&referrer_uri=${encodeURI(location.href)}`;
        },
        logout: () => {
          location.href = `${settings.authority}/protocol/openid-connect/logout`;
        }
      };
    }).catch(e => {
      console.warn("something went wrong when attempting to acquire token", e);
      return Promise.reject("something went wrong when attempting to authorise");
    });
  } else {
    // redirect to login page
    location.replace(`${settings.authority}/protocol/openid-connect/auth/?${new URLSearchParams({
      client_id: settings.client_id,
      response_type: "code",
      response_mode: "query",
      redirect_uri: settings.redirect_uri(),
    }).toString()}`);
    return Promise.reject("Redirecting to login");
  }
})();

export default Auth;
