import type { Alert } from "@virtalis/reach-theme-components";
import { createContext } from "react";

export interface ToasterContext {
  showToast?: (message: string, opts: {
    type: Parameters<typeof Alert>[0]['color'];
  }) => void;
}

const Context = createContext<ToasterContext>({});

export default Context;