import { useRef, useEffect, useState } from 'react'
import css from './Toaster.module.scss';
import { Alert } from '@virtalis/reach-theme-components';

export default function Toast({ onTransitionEnd, open, ...props }: Parameters<typeof Alert>[0] & {open:boolean}) {
  const itemRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [opened, setOpened] = useState(!open);

  useEffect(() => {
    const height = itemRef.current?.clientHeight ?? 0;
    setHeight(height);
    setOpened(open);
  }, [open]);

  return <div style={{ height }} >
    <div ref={itemRef} className={opened ? css['show'] : ''} onTransitionEnd={onTransitionEnd} >
      <Alert {...props} />
    </div>
  </div>
}
