import type { PropsWithChildren } from "react";

export default function PaginationWrapper({ children }: PropsWithChildren) {
  return <div style={{
    position: 'sticky',
    bottom: 0,
    width: 'max-content',
    margin: '4rem auto 0',
    paddingBottom: 8,
    background: 'white',
    borderRadius: '0.75rem 0.75rem 0 0',
    boxShadow: 'white 0 0 16px 16px'
  }} >
    <div style={{
      marginTop: -42,
      marginBottom: -14
    }} >
      {children}
    </div>
  </div>
}