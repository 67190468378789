import { useContext, useEffect, useState } from "react";
import PageContext from "../PageContext";
import { Alert, ProgressBar, Spinner } from "@virtalis/reach-theme-components";

const collaborationSessionPath = import.meta.env.VIRTALIS_COLLABORATION_SESSION_PATH as string;
if (!collaborationSessionPath)
  throw new Error("SessionPath undefined");
if (!import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME)
  throw new Error("Session server hostname not defined");
if (typeof import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME !== 'string')
  throw new Error("Session server hostname not string type");
const sessionServerHostname = new URL(
  (import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME).startsWith('/') ?
    `${location.origin}${import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME}` :
    import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME);

enum SessionStates {
  Unknown,
  Stopped,
  Starting,
  Ready
}

function isObject(v: unknown): v is object {
  return typeof v === 'object';
}
function isString(v: unknown): v is string {
  return typeof v === 'string';
}
function isNumber(v: unknown): v is number {
  return typeof v === 'number';
}

async function createNewSession(artifactName: string, artifactId: string, ownerUserId: string, accessToken: string) {
  const data = {
    artifactName,
    artifactId,
    sessionServerHostname: sessionServerHostname.hostname,
    sessionServerPort: 0,
    isPublic: true,
    isActive: true,
    ownerUserId
  };
  const response = await fetch(collaborationSessionPath, {
    method: "POST",
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok)
    throw new Error("Invalid response");

  const sessionId = await response.json().then((data) => {
    if (
      isObject(data) &&
      'sessionId' in data &&
      isString(data.sessionId)
    )
      return data.sessionId;
    throw new Error("Returned without valid sessionId");
  });

  return sessionId;
}

async function waitForSession(sessionId: string, accessToken: string) {
  // around here-ish we'll want to see what state the actors are in
  // while its starting we wait here
  // if its stopped let the user know something might have gone wrong
  // if its running then redirect to the client
  // pray we don't recive unknown
  let sessionState = SessionStates.Unknown
  do {
    const response = await fetch(`${collaborationSessionPath}/state/session/${sessionId}`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    sessionState = await response.json().then(data => {
      if (isObject(data) && 'status' in data && isNumber(data.status))
        return data.status;
      throw new Error("Invalid return format when checking loading status");
    });
  } while (sessionState == SessionStates.Starting);

  if (sessionState == SessionStates.Ready) {
    location.replace(`${sessionServerHostname.href}?session=${sessionId}`);
  }
}

export default function LoadingPage() {
  const { user, profile } = useContext(PageContext);
  const [error, setError] = useState<string>();
  const [created, setCreated] = useState(false);

  useEffect(() => {
    if (!user)
      throw new Error("User not logged in!");
    const parameters = new URLSearchParams(location.search);
    const ownerUserId = profile?.sub ?? '';
    let aborted = false;
    const catchAndDisplayError = (e: unknown) => {
      setError(v => aborted ? v : (e instanceof Error ? e.message : e as string));
    }

    const artifactId = parameters.get('artifactId');
    const sessionId = parameters.get('sessionId');
    if (sessionId) {
      if (artifactId)
        console.warn("ArtifactId supplied alongside sessionId, this is not required");
      setCreated(v => aborted ? v : true);
      waitForSession(
        sessionId,
        user.access_token
      ).catch(catchAndDisplayError);
    }
    else if (artifactId) {
      // A hack so I'm less likely to spam createNewSession multiple times on mount
      setTimeout(() => {
        if (aborted)
          return;

        const artifactName = parameters.get('artifactName');
        if (!artifactName)
          console.warn("Session is unnamed");

        createNewSession(
          artifactName ?? "SessionNameUndefined",
          artifactId,
          ownerUserId,
          user.access_token
        ).then(sessionId => {
          if (aborted)
            return;

          setCreated(v => aborted ? v : true);

          return waitForSession(
            sessionId,
            user.access_token
          );
        }).catch(catchAndDisplayError);
      }, 100);
    }
    else {
      catchAndDisplayError(new Error("No ArtifactID or SessionID has not been specified in URL"));
    }

    return () => { aborted = true; }
  }, [user, profile]);

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '8rem'
  }} >
    {error && <Alert color='danger'>{error}</Alert>}
    <span>
      <Spinner />
      <span>&nbsp;</span>
      <h2 style={{ display: 'inline' }} >Loading scene...</h2>
    </span>
    <ProgressBar progress={created ? 0.5 : 0.0}></ProgressBar>
  </div>
}